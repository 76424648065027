var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "feather feather-x-circle",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        color: _vm.color,
        viewBox: "0 0 24 24",
        fill: "none",
        stroke: "currentColor",
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
      },
    },
    [
      _vm.showCircle
        ? _c("circle", { attrs: { cx: "12", cy: "12", r: "10" } })
        : _vm._e(),
      _c("line", { attrs: { x1: "15", y1: "9", x2: "9", y2: "15" } }),
      _c("line", { attrs: { x1: "9", y1: "9", x2: "15", y2: "15" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }