<template>
  <transition name="modal">
    <div class="modal-mask" v-show="show">
      <div
        class="modal-container"
        :style="`width:${width ? width : 'max-content'};height:${height ? height : 'max-content'};background-color:${bkcolor};padding:${padding};`"
      >
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    width: {
      type: String,
      required: false
    },
    height: {
      type: String,
      required: false
    },
    bkcolor: {
      type: String,
      required: false,
      default: '#fff'
    },
    padding: {
      type: String,
      required: false,
      default: '10px 10px'
    }
  },

  mounted() {
    document.addEventListener('keydown', (e) => {
      if (this.show && e.keyCode === 27) {
        this.close()
      }
    })
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 64000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-container {
  margin: 0px auto;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
