var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show",
          },
        ],
        staticClass: "modal-mask",
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-container",
            style:
              "width:" +
              (_vm.width ? _vm.width : "max-content") +
              ";height:" +
              (_vm.height ? _vm.height : "max-content") +
              ";background-color:" +
              _vm.bkcolor +
              ";padding:" +
              _vm.padding +
              ";",
          },
          [_vm._t("default")],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }